import React from 'react'
import SeoPage from '../components/SeoPage'

import Img from '../components/Image'
import { graphql, Link } from 'gatsby'

export default ({ data, location, ...props }) => {
    return (
        <SeoPage
            leadImage={data.img0.childImageSharp.fluid.src}
            location={location}
            title="Recette avec avocat : 10 idées originales et savoureuses!"
            description="“Vous vous retrouvez à court d'idées pour utiliser vos avocats qui mûrissent à vue d'œil? Voici 10 recettes originales et savoureuses”"
            fr={undefined}
            lawType=""
            specialtyText="">
            <p>
                Il vous reste des avocats et ces derniers mûrissent à vue d'œil?
                Au lieu de simplement les mettre sur une tranche de pain ou dans
                un hamburger, il existe plusieurs recettes originales et
                savoureuses à essayer!
            </p>
            <p>
                <Img
                    fluid={data.img0.childImageSharp.fluid}
                    title="Idée de recettes savoureuses avec des avocats"
                    alt=""
                />
            </p>
            <p>
                Grâce à ces recettes, vous aurez plusieurs options pour déguster
                vos derniers avocats. De plus, dans certains cas, vous ne le
                goûterez même pas!
            </p>
            <p>
                Ces recettes faciles à préparer sont succulentes et extrêmement
                délicieuses. Elles offrent des options salées, mais aussi pour
                les dents sucrées!
            </p>
            <p>
                <strong>
                    Donc, à vos tabliers! Voici 10 idées originales et
                    savoureuses avec des avocats.
                </strong>
            </p>
            <h2>
                1. <em>Chips</em> à l’avocat
            </h2>
            <table>
                <tr>
                    <td>
                        <p>
                            <strong>Ingrédients:</strong>
                        </p>
                        <ul>
                            <li>1 gros avocat mûr,</li>
                            <li>¾ tasse de parmesan râpé,</li>
                            <li>1 c. à thé de jus de citron,</li>
                            <li>½ c. à thé de poudre d’ail,</li>
                            <li>½ c. à thé d’assaisonnement italien,</li>
                            <li>Sel et poivre.</li>
                        </ul>
                    </td>
                </tr>
            </table>
            <p>
                <strong>Étape 1: </strong>Préchauffer le four à 325°F. Dans un
                grand bol, écrasez l’avocat jusqu’à ce que ce dernier ressemble
                à un guacamole. Mélangez l’avocat avec les autres ingrédients.
            </p>
            <p>
                <strong>Étape 2: </strong>Prendre une cuillère à thé du mélange
                et déposer sur une plaque à cuisson en laissant 3” entre les
                cuillères. Cuire le mélange jusqu’à ce qu’il soit doré et
                croustillant (environ 30 minutes). Laissez reposer et servez à
                température pièce.
            </p>
            <h2>2. Frites à l’avocat</h2>
            <table>
                <tr>
                    <td>
                        <p>
                            <strong>Ingrédients:</strong>
                        </p>
                        <ul>
                            <li>3 avocats,</li>
                            <li>24 tranches de bacon, et </li>
                            <li>¼ tasse de sauce ranch.</li>
                        </ul>
                    </td>
                </tr>
            </table>
            <p>Si vous utilisez le four:</p>
            <p>
                <strong>Étape 1:</strong> Préchauffer le four à 425°F. Couper
                l’avocat en 8 tranches égales. Enrouler les tranches d’avocats
                dans le bacon. Déposer sur une plaque de cuisson.
            </p>
            <p>
                <strong>Étape 2</strong>: Cuire jusqu’à ce que le bacon soit
                croustillant (12 à 15 minutes).
            </p>
            <p>
                <strong>Étape 3:</strong> Servir avec de la sauce ranch.
            </p>
            <p>
                <Img
                    fluid={data.img1.childImageSharp.fluid}
                    title="Recette de frites à l’avocat"
                    alt=""
                />
            </p>
            <p>Si vous utilisez un Airfryer:</p>
            <p>
                <strong>Étape 1: </strong>Couper l’avocat en 8 tranches égales.
                Enrouler les avocats dans le bacon.
            </p>
            <p>
                <strong>Étape 2:</strong> Déposer les tranches d’avocat dans le
                Airfryer sans les superposer. Cuire à 400°F pour 8 minutes ou
                jusqu’à ce que le bacon soit croustillant.
            </p>
            <p>
                <strong>Étape 3: </strong>Servir avec la sauce ranch.
            </p>
            <h2>3. Crêpes aux avocats</h2>
            <table>
                <tr>
                    <td>
                        <p>
                            <strong>Ingrédients:</strong>
                        </p>
                        <ul>
                            <li>1 ½ tasse de farine tout-usage,</li>
                            <li>2 c. à thé de levure à cuisson,</li>
                            <li>1 c. à thé de sel</li>
                            <li>1 avocat,</li>
                            <li>1 c. à thé de jus de citron,</li>
                            <li>1 c. à soupe de miel,</li>
                            <li>1 c. à thé de zeste de citron,</li>
                            <li>⅔ tasse de babeurre,</li>
                            <li>2 oeufs,</li>
                            <li>1 c. à thé d’extrait de vanille,</li>
                            <li>Beurre pour la poêle, </li>
                            <li>
                                fruits et sirop d’érable pour accompagner vos
                                crêpes
                            </li>
                        </ul>
                    </td>
                </tr>
            </table>
            <p>
                <strong>Étape 1: </strong>Dans un grand bol, mélanger la farine,
                la levure et le sel.
            </p>
            <p>
                <strong>Étape 2: </strong>Dans un autre bol, écraser les avocats
                avec le jus de citron. Mélanger avec le miel, le zeste de citron
                et le babeurre. Ajouter les œufs et l’extrait de vanille.{' '}
            </p>
            <p>
                <strong>Étape 3: </strong>Combiner les éléments secs et ceux
                humides. Mélanger jusqu’à ce que vous obteniez un mélange
                homogène.{' '}
            </p>
            <p>
                <strong>Étape 4: </strong>Faire fondre le beurre dans la poêle
                sur votre four à température moyenne. Déposez ¼ de tasse de
                mélange dans la poêle. Cuire jusqu’à ce que des bulles
                apparaissent (3-4 minutes)
            </p>
            <p>
                <strong>Étapes 5:</strong> Tourner la crêpe et faire cuire
                pendant 3-4 minutes. Répéter jusqu’à ce que vous vidiez votre
                mélange.
            </p>
            <p>
                <strong>Étape 6: </strong>Servir avec le sirop d'érable est les
                fruits choisis.
            </p>
            <h2>4. Brownies au chocolat</h2>
            <table>
                <tr>
                    <td>
                        <p>
                            <strong>Ingrédients:</strong>
                        </p>
                        <ul>
                            <li>1 tasse de farine tout-usage,</li>
                            <li>½ tasse de poudre de cacao,</li>
                            <li>1 c. à thé de poudre à pâte,</li>
                            <li>1 c. à thé de cannelle moulue, </li>
                            <li>½ c. à thé de sel,</li>
                            <li>½ tasse d’avocats écrasés,</li>
                            <li>1 tasse de sucre,</li>
                            <li>2 œufs,</li>
                            <li>1 c. à thé d’extrait de vanille</li>
                            <li>1 ¼ tasse de zucchini râpé,</li>
                            <li>1 tasse de pépites de chocolat.</li>
                        </ul>
                    </td>
                </tr>
            </table>
            <p>
                <strong>Étape 1: </strong>Préchauffer le four à 350°F. Sur une
                planche de cuisson, déposer du papier parchemin. Dans un bol,
                mélanger la farine, la poudre de cacao, la poudre à pâte, la
                cannelle et le sel.
            </p>
            <p>
                <strong>Étape 2:</strong> Dans un autre bol, mélanger l’avocat,
                le sucre, les œufs et la vanille. Ajouter le zucchini et le
                mélange sec. Déposer les pépites de chocolat.
            </p>
            <p>
                <strong>Étape 3</strong>: Transférer le mélange sur la plaque à
                cuisson et cuire jusqu’à ce qu’il soit possible d’enfoncer un
                cure-dent dans le centre et qu’il reste propre (environ 1
                heure).
            </p>
            <p>
                <Img
                    fluid={data.img2.childImageSharp.fluid}
                    title="Recette de brownies au chocolat à l’avocat"
                    alt=""
                />
            </p>
            <p>
                <strong>Étape 4: </strong>Laisser refroidir et transférer sur
                une grille de refroidissement.
            </p>
            <h2>5. Fettuccine au pesto à l’avocat</h2>
            <table>
                <tr>
                    <td>
                        <p>
                            <strong>Ingrédient</strong>:
                        </p>
                        <ul>
                            <li>1 lb. de fettucine,</li>
                            <li>¼ tasse de pignons de pins,</li>
                            <li>2 avocats,</li>
                            <li>210 ml de pesto,</li>
                            <li>1 c. à soupe de jus de citron,</li>
                            <li>½ tasse de parmesan râpé,</li>
                            <li>¼ tasse de basilic coupé,</li>
                            <li>1 c. à thé flocons de piments rouges,</li>
                            <li>Extra d’huile d’olive. </li>
                        </ul>
                    </td>
                </tr>
            </table>
            <p>
                <strong>Étape 1: </strong>Faire bouillir votre eau salée et
                cuire les pâtes selon les instructions de l’emballage.
            </p>
            <p>
                <strong>Étape 2: </strong>Dans un poêlon, faire rôtir les
                pignons de pins à feu moyen jusqu’à ce qu’ils soient dorés
                (environ 5 minutes).{' '}
            </p>
            <p>
                <strong>Étape 3: </strong>Couper les avocats en deux et retirer
                le noyau. Mélanger l’avocat avec le pesto, le jus de citron
                jusqu’à ce que le mélange soit homogène.
            </p>
            <p>
                <strong>Étape 4: </strong>Transférer le pesto à l’avocat dans un
                bol. Ajouter les pâtes et la moitié du fromage. Déposer les
                pâtes dans l’assiette. Garnir avec les pignons de pins, le
                fromage, le basilic et le poivre rouge. Ajouter un filet d’huile
                d’olive et servir.
            </p>
            <h2>6. Salade mangues et avocats</h2>
            <table>
                <tr>
                    <td>
                        <p>
                            <strong>ingrédient:</strong>
                        </p>
                        <ul>
                            <li>⅓ tasse d’huile d’olive extra-vierge,</li>
                            <li>⅓ tasse de jus d’orange sanguine,</li>
                            <li>¼ tasse de jus de citron,</li>
                            <li>1 c. à soupe de miel,</li>
                            <li>1 c. à soupe de sel,</li>
                            <li>½ c. à thé de poivre,</li>
                            <li>1 échalote coupée.</li>
                            <li>2 c. à soupe de coriandre émincées,</li>
                            <li>4 oranges sanguines épluchées,</li>
                            <li>2 mangues, </li>
                            <li>2 avocats</li>
                            <li>1 tasse de tomates cerises.</li>
                        </ul>
                    </td>
                </tr>
            </table>
            <p>
                <strong>Étape 1: </strong>Mélanger l’huile, le jus d’orange
                sanguine, le jus de citron, le miel, le sel et le poivre dans un
                bol. Ajouter l’échalote et la coriandre.
            </p>
            <p>
                <strong>Étape 2: </strong>Déposer les oranges sanguines, les
                mangues, les avocats et les tomates cerises dans un bol. Déposer
                la vinaigrette.
            </p>
            <h2>7. Mousse au chocolat</h2>
            <table>
                <tr>
                    <td>
                        <p>
                            <strong>Ingrédients:</strong>
                        </p>
                        <ul>
                            <li>2 avocats, </li>
                            <li>¾ tasse de crème 35%,</li>
                            <li>½ tasse de pépites de chocolat fondues,</li>
                            <li>¼ tasse de miel,</li>
                            <li>3 c. à soupe de poudre de cacao</li>
                            <li>1 c. à thé de vanille,</li>
                            <li>½ c. à thé de sel</li>
                        </ul>
                    </td>
                </tr>
            </table>
            <p>
                <strong>Étape 1: </strong>Déposer dans un mélangeur tous les
                ingrédients jusqu’à ce que le mélange soit homogène.
            </p>
            <p>
                <strong>Étape 2:</strong> Transférer le mélange dans des
                récipients et déposer dans un réfrigérateur pour une période
                entre 30 minutes et 1 heure.
            </p>
            <h2>8. Guacamole</h2>
            <table>
                <tr>
                    <td>
                        <p>
                            <strong>Ingrédients: </strong>
                        </p>
                        <ul>
                            <li>2 avocats coupés en deux</li>
                            <li>2 c. à soupe de jus de lime</li>
                            <li>¼ tasse de feuilles de coriandre,</li>
                            <li>1 oignon vert</li>
                            <li>Sauce Tabasco</li>
                        </ul>
                    </td>
                </tr>
            </table>
            <p>
                <Img
                    fluid={data.img3.childImageSharp.fluid}
                    title="Recette simple de guacamole"
                    alt=""
                />
            </p>
            <p>
                <strong>Étape 1: </strong>Dans un grand bol, écraser les avocats
                et le jus de lime. Ajouter les autres ingrédients et bien
                mélanger.
            </p>
            <p>
                <strong>Étape 2: </strong>Servir avec des croustilles.{' '}
            </p>
            <h2>9. Sucette glacée à l’avocat</h2>
            <table>
                <tr>
                    <td>
                        <p>
                            <strong>Ingrédients:</strong>
                        </p>
                        <ul>
                            <li>1 avocat coupé en dé,</li>
                            <li>⅔ tasse de lait évaporé,</li>
                            <li>⅓ tasse de lait sucré concentré,</li>
                            <li>¼ tasse de sucre, </li>
                            <li>1 tasse d’eau, </li>
                            <li>2 limes</li>
                        </ul>
                    </td>
                </tr>
            </table>
            <p>
                <strong>Étape 1: </strong>Dans un mélangeur, ajouter l’avocat,
                le lait évaporé, le lait concentré ainsi que l’eau jusqu’à ce
                que vous obteniez une purée lisse.
            </p>
            <p>
                <strong>Étape 2: </strong>Déposer le mélange dans les moules
                pour vos sucettes glacées. Enfoncer les bâtonnets et congeler
                pendant au moins 5 heures.
            </p>
            <p>
                <strong>Étapes 3: </strong>Démouler les sucettes et verser du
                jus de lime sur ces dernières.{' '}
            </p>
            <h2>10. Glaçage au chocolat</h2>
            <table>
                <tr>
                    <td>
                        <p>
                            <strong>Ingrédients:</strong>
                        </p>
                        <ul>
                            <li>¼ tasse de cacao,</li>
                            <li>¼ tasse d’eau,</li>
                            <li>1 avocat mûr, coupé en dés,</li>
                            <li>⅔ tasse de sucre à glacer</li>
                            <li>½ c. à thé d’extrait de vanille,</li>
                            <li>Une pincée de sel.</li>
                        </ul>
                    </td>
                </tr>
            </table>
            <p>
                <strong>Étape 1: </strong>Dans un bol, mélanger le cacao et
                l’eau. Cuire jusqu’à ébullition au micro-ondes (entre 30 et 60
                secondes).{' '}
            </p>
            <p>
                <strong>Étapes 2: </strong>Déposer dans un mélangeur l’avocat,
                le sucre, la vanille et le sel. Mélanger jusqu’à obtenir une
                purée lisse. Ajouter la préparation au chocolat et continuer à
                mélanger jusqu’à obtenir une substance homogène. Refroidir et
                utiliser.{' '}
            </p>
            <h2>
                Voici 10 idées de recettes afin de vous débarrasser de vos
                avocats!
            </h2>
            <p>
                Grâce à ces 10 succulentes recettes, vous serez en mesure
                d’utiliser vos avocats restants tout en savourant de délicieux
                plats faciles à préparer.
            </p>
            <p>
                En plus d’être bon pour la santé, les avocats sont un bon
                substituant dans plusieurs types de recettes que ce soit un plat
                salé ou sucré.
            </p>
            <p>
                <strong>
                    Si vous décidez de préparer l’une de ces 10 recettes à
                    l’avocat, il ne reste qu’une seule chose à dire: à vos
                    fourneaux et bons appétits!
                </strong>
            </p>
        </SeoPage>
    )
}

export const pageQuery = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        img0: file(
            relativePath: {
                eq: "idees-recettes-avocats/idee-recette-avocat.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img1: file(
            relativePath: {
                eq: "idees-recettes-avocats/recette-frite-avocat.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img2: file(
            relativePath: {
                eq: "idees-recettes-avocats/brownie-avocat-chocolat.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img3: file(
            relativePath: {
                eq: "idees-recettes-avocats/guacamole-avocat-recette.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`
